@use '/src/styles/core' as *;

.error-boundary {
  max-width: 1140px;
  margin: 0 auto;
  padding: 2.959em 1.901em;
}

.error-boundary-description {
  max-width: 620px;
}

.error-boundary-button {
  color: $theme-palette-metra-l-20;
  background: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
}
