@use 'styles/core' as *;

/* Fonts */

/* Roboto Regular 400 */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/Roboto/Roboto-Regular.ttf');
}

/* Roboto Light 300 */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('../src/assets/fonts/Roboto/Roboto-Light.ttf');
}

/* Roboto Medium 500 */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('../src/assets/fonts/Roboto/Roboto-Medium.ttf');
}

/* Roboto Bold 700 */
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('../src/assets/fonts/Roboto/Roboto-Bold.ttf');
}

/* Roboto Italic Regular 400 */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url('../src/assets/fonts/Roboto/Roboto-Italic.ttf');
}

/* Roboto Light Italic 300 */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url('../src/assets/fonts/Roboto/Roboto-LightItalic.ttf');
}

/* Roboto Medium Italic 500 */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url('../src/assets/fonts/Roboto/Roboto-MediumItalic.ttf');
}

/* Roboto Bold Italic 700 */
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  src: url('../src/assets/fonts/Roboto/Roboto-BoldItalic.ttf');
}



.body-main {
  margin: 0;
  padding: 0;
  font-size: font-size(18);
  line-height: font-size(31);
  color: $theme-palette-smoke;
  background-color: $theme-palette-metal-bkgd;
  height: 100%;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

// GLOBALS
html {
  box-sizing: border-box;
  height: 100%;
  font-family: $theme-font-family;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

abbr {
  cursor: default;
}

h1 {
  font-size: font-size(34);
  line-height: calc(28px + 0.8vw);
}

h2 {
  font-size: font-size(26);
  line-height: calc(22px + 0.8vw);
}

h3 {
  font-size: font-size(24);
}

h4 {
  font-size: font-size(22);
}

s
h1,
h2,
h3,
h4 {
  margin: calc(18px + 0.8vw) 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  hyphens: auto;
}

small,
.fs-small-16 {
  font-size: font-size(16);
} /* 16px */
.fs-small-14 {
  font-size: font-size(14);
} /* 14px (.797em) */
.fs-small-12 {
  font-size: font-size(12);
} /* 12px */

.fw-bold{
  font-weight: bold;
}
