// Typography

$theme-font-family: roboto, sans-serif;

// Color Palette

// Tenet3
$theme-palette-tenet3: hsl(196deg, 89%, 62%);
$theme-palette-tenet3-light: hsl(196deg, 89%, 93%);
$theme-palette-tenet3-dark: hsl(196deg, 89%, 31%);

// MeTRA
$theme-palette-metra: hsl(221deg, 100%, 56%); //#1f66ff

$theme-palette-metra-l-20: #588dff;
$theme-palette-metra-l-25: #6696ff;
$theme-palette-metra-l-30: #74a0ff;
$theme-palette-metra-l-40: #91b4ff;
$theme-palette-metra-l-50: #adc7ff;
$theme-palette-metra-l-60: #cadbff;
$theme-palette-metra-l-70: #e7eeff;
$theme-palette-metra-l-75: #f5f8ff;
$theme-palette-metra-l-80: #fff;

$theme-palette-metra-d-10: #0554ff;
$theme-palette-metra-d-20: #004bee;
$theme-palette-metra-d-25: #0048e4;
$theme-palette-metra-d-30: #0046dc;
$theme-palette-metra-d-40: #0041cc;
$theme-palette-metra-d-50: #003cbe;
$theme-palette-metra-d-60: #0039b3;
$theme-palette-metra-d-70: #0035a8;
$theme-palette-metra-d-75: #0034a3;
$theme-palette-metra-d-80: #00329f;
$theme-palette-metra-d-90: #003096;
$theme-palette-metra-d-100: #002d8f;

$theme-palette-metra-light2: hsl(221deg, 100%, 63%);
$theme-palette-metra-light3: hsl(221deg, 100%, 73%);
$theme-palette-metra-light: hsl(221deg, 100%, 84%);
$theme-palette-metra-dark: hsl(221deg, 100%, 28%);
$theme-palette-metra-dark2: hsl(221deg, 100%, 45%);
$theme-palette-metra-dark3: hsl(221deg, 100%, 35%);

//Smoke
$theme-palette-smoke: hsl(285deg, 3%, 28%);

$theme-palette-smoke-l-10: #504c51;
$theme-palette-smoke-l-20: #575358;
$theme-palette-smoke-l-25: #5b575c;
$theme-palette-smoke-l-30: #5e5a60;
$theme-palette-smoke-l-40: #656167;
$theme-palette-smoke-l-50: #6d686e;
$theme-palette-smoke-l-60: #746f76;
$theme-palette-smoke-l-70: #7b767d;
$theme-palette-smoke-l-75: #7f7981;
$theme-palette-smoke-l-80: #827d84;
$theme-palette-smoke-l-90: #89848b;
$theme-palette-smoke-l-100: #908b92;

$theme-palette-smoke-d-10: #423f43;
$theme-palette-smoke-d-20: #3c3a3d;
$theme-palette-smoke-d-25: #3a373b;
$theme-palette-smoke-d-30: #383539;
$theme-palette-smoke-d-40: #343135;
$theme-palette-smoke-d-50: #302e31;
$theme-palette-smoke-d-60: #2d2b2e;
$theme-palette-smoke-d-70: #2b292b;
$theme-palette-smoke-d-75: #29282a;
$theme-palette-smoke-d-80: #282629;
$theme-palette-smoke-d-90: #262427;
$theme-palette-smoke-d-100: #242325;

$theme-palette-smoke-light: hsl(285deg, 3%, 42%);
$theme-palette-smoke-dark: hsl(285deg, 3%, 14%);

//Grape
$theme-palette-grape: hsl(294deg, 13%, 16%);

$theme-palette-grape-l-10: #322733;
$theme-palette-grape-l-20: #362b37;
$theme-palette-grape-l-25: #382c3a;
$theme-palette-grape-l-30: #3b2e3c;
$theme-palette-grape-l-40: #3f3241;
$theme-palette-grape-l-50: #443545;
$theme-palette-grape-l-60: #48394a;
$theme-palette-grape-l-70: #4d3c4e;
$theme-palette-grape-l-75: #4f3e51;
$theme-palette-grape-l-80: #514053;
$theme-palette-grape-l-90: #564358;
$theme-palette-grape-l-100: #5a475c;

$theme-palette-grape-d-10: #29202a;
$theme-palette-grape-d-20: #261e26;
$theme-palette-grape-d-25: #241c25;
$theme-palette-grape-d-30: #231b23;
$theme-palette-grape-d-40: #201921;
$theme-palette-grape-d-50: #1e181f;
$theme-palette-grape-d-60: #1c161d;
$theme-palette-grape-d-70: #1a151b;
$theme-palette-grape-d-75: #1a141a;
$theme-palette-grape-d-80: #19141a;
$theme-palette-grape-d-90: #181318;
$theme-palette-grape-d-100: #171217;

$theme-palette-grape-light: hsl(294deg, 13%, 24%);
$theme-palette-grape-light2: hsl(294deg, 13%, 20%);
$theme-palette-grape-dark: hsl(294deg, 13%, 8%);

//Metal
$theme-palette-metal: hsl(240deg, 2%, 92%);
$theme-palette-metal-bkgd: hsl(0deg, 0%, 96%);
$theme-palette-metal-highlight: hsl(0deg, 0%, 97%);

$theme-palette-metal-d-05: #e0e0e2;
$theme-palette-metal-d-10: #d4d4d6;
$theme-palette-metal-d-20: #c2c2c5;
$theme-palette-metal-d-25: #bababd;
$theme-palette-metal-d-30: #b3b3b6;
$theme-palette-metal-d-40: #a6a6a9;
$theme-palette-metal-d-50: #9a9a9e;
$theme-palette-metal-d-60: #909095;
$theme-palette-metal-d-70: #88888c;
$theme-palette-metal-d-75: #848488;
$theme-palette-metal-d-80: #808085;
$theme-palette-metal-d-90: #79797e;
$theme-palette-metal-d-100: #737378;

$theme-palette-metal-dark2: hsl(240deg, 5%, 82%);
$theme-palette-metal-dark3: hsl(240deg, 5%, 72%);
$theme-palette-metal-dark4: hsl(240deg, 5%, 62%);
$theme-palette-metal-dark5: hsl(240deg, 5%, 52%);
$theme-palette-metal-light: hsl(240deg, 2%, 138%);
$theme-palette-metal-dark: hsl(240deg, 2%, 46%);

//Feedback Colors
$theme-palette-error: hsl(350deg, 56%, 54%);
$theme-palette-attention: hsl(42deg, 75%, 58%);
$theme-palette-success: hsl(130deg, 37%, 46%);
$theme-palette-information: hsl(221deg, 100%, 28%);

$theme-palette-text-black: rgba(32, 32, 32, 80%);
$theme-palette-text-grey: rgba(64, 64, 64, 80%);
$theme-palette-text-light-grey: rgba(128, 128, 128, 80%);
$theme-palette-text-white: rgba(255, 255, 255, 80%);
$theme-palette-text-white-bright: rgba(255, 255, 255, 100%);

$theme-palette-model-background: rgb(242, 242, 242);

$theme-palette-gradient-purple: hsl(250deg, 100%, 60%);

$theme-gradient-tenet-metra: linear-gradient(
  to right,
  $theme-palette-tenet3,
  $theme-palette-metra,
  $theme-palette-gradient-purple
);
$theme-gradient-tenet-metra-light: linear-gradient(
  to right,
  $theme-palette-tenet3-light,
  $theme-palette-metra-light
);

// Theme

$theme-rounded-corner: 5px;

// heights
$theme-header-height: 42px;
$theme-model-header-height: 42px;
$theme-footer-height: 31px;
$theme-cui-footer-height: 21px;
$theme-appbar-height: 76px;
$theme-project-header-height: 98px;
$theme-project-top: 164px;
$theme-app-footer-height: 36px;

// spacing
$theme-spacing-unit: 8px;
$data-cell-top-padding: 2px;
$data-cell-content-top-padding: 8px;

// shadows
$theme-shadow-1: 0 1px 2px 0 rgba(0, 0, 0, 12%),
                 0 2px 4px 0 rgba(0, 0, 0, 24%);
$theme-shadow-1-light: 0 1px 2px 0 rgba(0, 0, 0, 3%),
                       0 2px 4px 0 rgba(0, 0, 0, 6%);
$theme-shadow-2: 0 2px 4px 0 rgba(0, 0, 0, 12%),
                 0 4px 8px 0 rgba(0, 0, 0, 24%);
$theme-shadow-2-light: 0 2px 4px 0 rgba(0, 0, 0, 3%),
                       0 4px 8px 0 rgba(0, 0, 0, 6%);
$theme-shadow-3: 0 3px 6px 0 rgba(0, 0, 0, 12%),
                 0 6px 12px 0 rgba(0, 0, 0, 24%);
$theme-shadow-3-light: 0 3px 6px 0 rgba(0, 0, 0, 3%),
                       0 6px 12px 0 rgba(0, 0, 0, 6%);
$theme-shadow-4: 0 4px 8px 0 rgba(0, 0, 0, 12%),
                 0 8px 16px 0 rgba(0, 0, 0, 24%);
$theme-shadow-4-light: 0 4px 8px 0 rgba(0, 0, 0, 3%),
                       0 8px 16px 0 rgba(0, 0, 0, 6%);
$theme-btn-shadow-1: 0 2px 3px rgba(black, 12%), 0 2px 2px rgba(black, 24%);
$theme-btn-shadow-2: 0 6px 10px rgba(black, 25%), 0 2px 5px rgba(black, 22%);

// breakpoints
$theme-breakpoint-xs: 320px;
$theme-breakpoint-sm: 480px;
$theme-breakpoint-md: 720px;
$theme-breakpoint-lg: 960px;
$theme-breakpoint-xl: 1080px;

$theme-breakpoint-min: 1024px;
$theme-breakpoint-max: 1920px;

// transitions
$theme-transition-cubic: cubic-bezier(0.25, 0.8, 0.25, 1);

// form common
$theme-form-min-width: 120px;

// table common
$theme-max-td-width: 200px;