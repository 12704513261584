@use '/src/styles/core' as *;

//LoadingBar
.loader-container {
  width: 100%;
  height: min-content;
  overflow: hidden;

  .inactive-loader {
    height: 4px;
    width: 100%;
  }

  .loader {
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: $theme-palette-metal;
  }

  .loader::before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: $theme-palette-metra;
    animation: loading 2s linear infinite;
  }


}

  @keyframes loading {
    0% {
      left: -200px;
      width: 30%;
    }

    50% {
      width: 30%;
    }

    70% {
      width: 70%;
    }

    80% {
      left: 50%;
    }

    95% {
      left: 120%;
    }

    100% {
      left: 100%;
    }
  }
