@use '/src/styles/core' as *;

.broken-link {
  display: flex;
  align-items: flex-start;

  &-icon {
    min-width: 13px;
    margin-right: 5px;
    margin-top: 2px;
  }
}

p.broken-link-text {
    margin-block-start: 0;
    margin-block-end: 0;
}
