.icon {
  position: relative;
  vertical-align: baseline;
  color: currentcolor;
  width: 1em;
  height: 1em;

  // disable browser tooltips, the worst alternative is to remove the title tag instead.
  pointer-events: none;


  // width or height attributes are set... set width and height to auto
  // and use attribute values
  &[width] {
    width: auto;
  }

  &[height] {
    height: auto;
  }
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
}
