@use 'styles/core' as *;

/*
 * COLORS CLASSES
 */
//colors use !important because if they are used we want them to override
//anything they are attached to i.e., mod-pill-btn
.mod-primary {
  color: white !important;
  background: $theme-palette-grape !important;
}

.mod-secondary {
  color: $theme-palette-smoke !important;
  background: hsl(0deg,0,88%) !important;
}

.mod-border-secondary {
  border: 1px solid hsl(0deg, 6%, 83%) !important;
}

.mod-primary-blue {
  color: white !important;
  background-color: $theme-palette-metra !important;
}

.mod-primary-white {
  background: white !important;
  color: $theme-palette-grape !important;
}

.mod-secondary-link-btn {
  color: $theme-palette-smoke-l-20 !important;
}

.mod-success {
  color: white !important;
  background-color: $theme-palette-success !important;
}

.mod-error {
  color: white !important;
  background-color: $theme-palette-error !important;
}

.mod-error-text {
  color: $theme-palette-error !important;
}

.mod-success-text {
  color: $theme-palette-success !important;
}

.mod-blue-link {
  &,
  &:visited {
    color: $theme-palette-metra-l-20;
    text-decoration: none;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

/*
 * FORM CLASSES
 */
.mod-label-vertical {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.mod-label-horizontal {
  display: flex;
  flex-direction: row;
  text-align: left;
  position: relative;
  overflow: hidden;
}

.mod-layout-vertical {
  display: flex;
  flex-direction: column;
}

.mod-layout-horizontal {
  display: flex;
  flex-direction: row;
}

.mod-layout-horizontal-centered {
  @extend .mod-layout-horizontal;

  align-items: center;
}

.mod-control {
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: inset 0 -1px $theme-palette-metal-d-25;
  color: $theme-palette-smoke-l-75;

  &:not([disabled]):hover {
    box-shadow: inset 0 -2px $theme-palette-metal-d-25;
  }

  &:focus-within {
    box-shadow: none;
  }
}

.mod-input {
  outline: none;
  border: none;
  background: transparent;

  &[error='true'] {
    color: $theme-palette-error;
  }
}

.mod-textarea {
  &[error='true'] {
    color: $theme-palette-error;
  }
}

.mod-input-text {
  @extend .mod-input;

  color: $theme-palette-text-black;
}

.mod-expando-border {
  margin: auto;
  width: 0;
  height: 2px;
  position: relative;
  top: 0;
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.mod-control:focus-within .mod-expando-border:not([disabled]) {
  width: 100%;
  background-color: $theme-palette-metra;
}

.mod-input[error='true'] + .mod-expando-border {
  width: 100%;
  background-color: $theme-palette-error !important;
}

.mod-validation {
  position: absolute;
  right: 0;
  font-size: 0.7rem;
  color: $theme-palette-error;
}

/*
 * STYLING
 */
.mod-pill {
  padding: 8px 18px;
  text-align: center;
  border-radius: $theme-spacing-unit * 3;
}

.mod-rect {
  padding: 14px 18px;
  text-align: center;
  border-radius: calc($theme-spacing-unit / 2) !important;
}

//Font Awesome Icons
.mod-svg-inline {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: z(icons);
}

.mod-hidden {
  visibility: hidden;
}

/*
 * BUTTONS
 */
.mod-btn {
  align-items: center;
  text-align: center;
  border: none;
  font-size: font-size(14);
  user-select: none;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding-left: $theme-spacing-unit * 2;
  padding-right: $theme-spacing-unit * 2;
  
  &,
  &:visited {
    outline: none;
    border-radius: $theme-spacing-unit * 3;
    color: white;
    text-decoration: none;
    background: red; //This is so we don't forget to declare a background color, red is going to stand out.
    box-shadow: 0 2px 3px rgba(black, 0.12), 0 2px 2px rgba(black, 0.24);
  }

  &:hover,
  &:active,
  &:focus {
    color: white;
    text-decoration: none;
    box-shadow: 0 6px 10px rgba(black, 0.25), 0 2px 5px rgba(black, 0.22);
  }

  &:disabled {
    box-shadow: none;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.mod-rect-btn {
  @extend .mod-btn;
  @extend .mod-rect;

  &:disabled {
    background-color: $theme-palette-metal;
    color: $theme-palette-metal-d-40;
    box-shadow: none;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.mod-pill-btn {
  @extend .mod-btn;
  @extend .mod-pill;

  &:disabled {
    background-color: $theme-palette-metal;
    color: $theme-palette-metal-d-40;
    box-shadow: none;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.mod-link-btn {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  padding: 8px 17px;
  position: relative;
  outline: none;
  border: none;
  border-radius: 100px;
  font-size: font-size(14);
  color: $theme-palette-metra;
  background: transparent;
  z-index: inherit;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    border-radius: 100px;
    z-index: z(below) - 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    border-radius: 100px;
    z-index: z(below);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: circle(0%);
    background-color: rgba($theme-palette-metal-d-10, 0.5);
  }

  &:hover,
  &:focus {
    background-color: transparent;

    &::before {
      transition: 0.3s $theme-transition-cubic;
      background-color: rgba($theme-palette-metal-d-05, 0.5);
    }
  }

  &:active {
    &::after {
      clip-path: circle(100%);
    }
  }

  &:disabled {
    background-color: $theme-palette-metal;
    color: $theme-palette-metal-d-40;
    box-shadow: none;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

.mod-disable-btn {
  width: 100%;
  border: none;
  outline-style: none;
  cursor: pointer;
  padding: 10px 18px;
  transition: 0.3s;

  &,
  &:visited {
    border-radius: 100px;
    color: white;
    background-color: $theme-palette-grape;
    text-decoration: none;
    box-shadow: 0 2px 3px rgba(black, 0.12), 0 2px 2px rgba(black, 0.24);
  }

  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: $theme-palette-grape-l-25;
    box-shadow: 0 6px 10px rgba(black, 0.25), 0 2px 5px rgba(black, 0.22);
  }

  &:disabled {
    background-color: $theme-palette-metal;
    color: $theme-palette-metal-d-40;
    box-shadow: none;

    &,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      pointer-events: none;
    }
  }
}

//Icon buttons
.mod-icon-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: $theme-spacing-unit;
  position: relative;
  border: none;
  cursor: pointer;
  background: transparent;
  z-index: z(icons);
  color: $theme-palette-metra;
  transition: all 0.3s $theme-transition-cubic;
  box-shadow: none;
  border-color: transparent;

  &::before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-radius: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s $theme-transition-cubic;
    transform-origin: center;
    z-index: z(below) - 1;
  }

  //Background circle
  &::after {
    content: '';
    position: absolute;
    border-radius: 100px;
    z-index: z(below);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: circle(0%);
    background-color: rgba($theme-palette-metal-d-20, 0.5);
    transition: all 0.3s $theme-transition-cubic;
  }

  &:not(:disabled, .is-disabled):hover {
    &::before {
      width: 100%;
      height: 100%;
      background-color: rgba($theme-palette-metal-d-05, 0.5);
    }
  }

  &:not(:disabled, .is-disabled):active {
    color: $theme-palette-metra-d-50;

    &::after {
      clip-path: circle(100%);
    }
  }

  &:disabled,
  .is-disabled {
    color: $theme-palette-metal-d-25;
    pointer-events: none;
  }
}

/*
 * OTHER
 */

.mod-blur-bg {
  backdrop-filter: blur(5px);
}

.mod-progress-bar {
  display: block;
  height: 6px;
  width: 100%;
  position: relative;
  background-color: $theme-palette-metal;
  border-radius: $theme-spacing-unit;
  margin: $theme-spacing-unit 0;

  &::after {
    border-radius: $theme-spacing-unit;
    position: absolute;
    content: '';
    width: 0%;
    left: 0%;
    height: 6px;
    background-image: linear-gradient(
      to right,
      $theme-palette-metal 10%,
      $theme-palette-metra 50%,
      $theme-palette-metal 90%
    );
    animation: progress-loading 2s linear infinite;
  }
}

.mod-vertical-divider {
  content: ' ';
  margin: auto calc($theme-spacing-unit / 2);
  min-width: 1px;
  height: 25px;
  box-shadow: -2px 0 0 0 $theme-palette-metal-dark2;
}

/* The switch - the box around the slider */
.mod-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 20px;
}

/* Hide default HTML checkbox */
.mod-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.mod-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $theme-palette-metal-d-25;
  border-radius: calc($theme-spacing-unit / 2);
  transition: all 0.3s $theme-transition-cubic;

  &::before {
    position: absolute;
    content: '';
    height: 16px;
    width: 10px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    border-radius: calc($theme-spacing-unit / 3);
    transition: all 0.3s $theme-transition-cubic;
  }
}

.mod-switch-input:checked + .mod-slider {
  background-color: $theme-palette-metra;
}

.mod-switch-input:checked + .mod-slider::before {
  transform: translateX(6px);
}

@keyframes progress-loading {
  0% {
    left: 0%;
    width: 0%;
  }

  12% {
    left: 6%;
    width: 12%;
  }

  25% {
    left: 25%;
    width: 50%;
  }

  37% {
    left: 81%;
    width: 12%;
  }

  50% {
    left: 100%;
    width: 0%;
  }

  62% {
    left: 81%;
    width: 12%;
  }

  75% {
    left: 25%;
    width: 50%;
  }

  87% {
    left: 6%;
    width: 12%;
  }

  100% {
    width: 0%;
    left: 0%;
  }
}

.mod-animation-shake {
  animation: shake 0.25s ease;
}

@keyframes shake {
  0% {
    margin-left: 0;
  }

  25% {
    margin-left: 10px;
  }

  50% {
    margin-left: 0;
  }

  75% {
    margin-left: 10px;
  }

  100% {
    margin-left: 0;
  }
}

.mod-slide-from-right-enter {
  transform: translateX(100%);
}

.mod-slide-from-right-enter-active {
  transform: translateX(0%);
  transition: transform 0.5s $theme-transition-cubic;
}

.mod-slide-from-right-exit {
  transform: translateX(0%);
}

.mod-slide-from-right-exit-active {
  transform: translateX(100%);
  transition: transform 0.5s $theme-transition-cubic;
}

.mod-slide-from-right-exit-done {
  transform: translateX(100%);
}

.mod-slide-transition {
  transition: transform 0.5s $theme-transition-cubic;
}

.help-border-top {
  border-top: 1px solid $theme-palette-metal-d-10;
}

.help-border-bottom {
  border-bottom: 1px solid $theme-palette-metal-d-10;
}